import { useRef, useMemo, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import useGetMainCookie from '../../../hooks/useGetMainCookie';
import useCookies from '../../../hooks/useCookies';
import { LANG_CODE } from '../../../helpers/constant';
import { decrypt } from '../../../helpers/cryptography';
import { ModeAccessList } from '../../../interfaces';

interface AdditionalIFrameForm {
  key: string;
  value: string;
}
interface HumatrixIFrameProps {
  menuCode?: string;
  additionalIFrameForm?: AdditionalIFrameForm[];
}

const InputParam = ({ param }: { param: string }) => {
  const [key, value] = param.split('=');

  return <input type="hidden" name={key} value={value} />;
};

const InputParamList = ({ params }: { params: string[] }) => {
  return (
    <>
      {params?.map((param) => (
        <InputParam param={param} />
      ))}
    </>
  );
};

const HumatrixIFrame: React.FC<HumatrixIFrameProps> = ({
  menuCode,
  additionalIFrameForm = [],
}) => {
  const { currentRole, currentOU } = useGetMainCookie();
  const { getCookies } = useCookies();
  const tokenCookie = getCookies('jwtToken', true);
  const location = useLocation();
  const ref = useRef<HTMLFormElement>(null);

  const {
    menuCode: matchMenuCode,
    url,
    params = [],
  } = useMemo(() => {
    const currentPath = location.pathname.replace(/^\/|\/$/g, '');
    const encryptedModeAccessList = localStorage.getItem('modeAccess');

    if (!encryptedModeAccessList) return {};

    const decryptedModeAccessList: ModeAccessList[] = decrypt({
      value: encryptedModeAccessList,
    });

    const matchingModeAccess = decryptedModeAccessList.find(
      ({ path }) => path.replace(/^\/|\/$/g, '') === currentPath
    );

    return {
      menuCode: matchingModeAccess?.menuCode || '',
      url: matchingModeAccess?.ssoUrl || '',
      params: matchingModeAccess?.ssoParams?.split(',') || [],
    };
  }, []);

  const finalMenuCode = menuCode || matchMenuCode;

  const submitFormToIFrame = () => {
    const form = ref.current;
    if (form) {
      form['SID'].value = tokenCookie.fssid;
      form['ROLE'].value = currentRole;
      form['OU'].value = currentOU;
      form['LANG'].value = LANG_CODE();
      form['ScreenCode'].value = finalMenuCode;

      additionalIFrameForm?.forEach(({ key, value }) => {
        form[key].value = value;
      });
      form.submit();
    }
  };

  useEffect(() => {
    submitFormToIFrame();
  }, []);

  return (
    <>
      <iframe
        name="humatrixIFrame"
        style={{
          border: 'none',
          borderRadius: '16px',
          width: '100%',
          height: 'calc(100dvh - 130px)',
        }}
      />

      <form
        ref={ref}
        id={`${finalMenuCode}_iframeForm`}
        action={url}
        method="post"
        target="humatrixIFrame"
      >
        <input type="hidden" name="ScreenCode" />
        <InputParamList params={params} />
      </form>
    </>
  );
};

export default HumatrixIFrame;
