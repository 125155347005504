import { Flex, Form, Input, Typography } from 'antd';
import PasswordValidator, {
  PasswordValidationSetting,
} from '../password-validator/PasswordValidator';
import React, { useState } from 'react';

import InputPin from '../../controls/InputPin';
import { useTranslate } from '../../../hooks/useResourceKey';

interface ChangePasswordFormProp {
  oldPasswordLabel?: string;
  newPasswordLabel: string;
  confirmPasswordLabel: string;
  passwordValidationSetting: PasswordValidationSetting;
  formType: FormType;
  securitySetting?: 'PIN' | 'Password';
}

type FormType = 'forgot' | 'change' | 'force';

const ChangePasswordForm: React.FC<ChangePasswordFormProp> = ({
  oldPasswordLabel,
  newPasswordLabel,
  confirmPasswordLabel,
  passwordValidationSetting,
  formType,
  securitySetting,
}) => {
  // Translation hooks for various labels
  const labelPasswordNotMatch = useTranslate(
    'Confirmpasswordnotmatch',
    'Your confirm Password does not match New Password'
  );
  const labelPinNotMatch = useTranslate(
    'confirmPINNotMatch',
    'Your confirm PIN does not match your PIN'
  );
  const labelOldPasswordRequired = useTranslate(
    'OldPasswordIsRequired',
    'Old Password is required.'
  );
  const labelNewPasswordRequired = useTranslate(
    'NewPasswordIsRequired',
    'New Password is required.'
  );
  const labelConfirmPasswordRequired = useTranslate(
    'ConfirmPasswordIsRequired',
    'Confirm Password is required.'
  );
  const labelOldPinRequired = useTranslate(
    'RequireOldPIN',
    'Old PIN is required.'
  );
  const labelNewPinRequired = useTranslate(
    'NewPINrequired',
    'New PIN is required.'
  );
  const labelConfirmPinRequired = useTranslate(
    'ConfirmPINrequired',
    'Confirm PIN is required.'
  );
  const labelInputPassword = useTranslate('InputPassword', 'Input Password');
  const labelNumericOnly = useTranslate(
    'PINsecurity',
    'PIN must be in numerics only'
  );
  const labelValidatePinInformation = useTranslate(
    'CreatePIN',
    'PIN must be at 6 digits and containing no repetitive or sequence numbers'
  );
  const labelBothPINMustMatch = useTranslate(
    'BothPINmatch',
    'Both PIN must match'
  );

  // State to manage password and touched status
  const [password, setPassword] = useState<string>('');
  const [touched, setTouched] = useState<boolean>(false);

  // Function to validate password based on security setting
  const validatePassword = (rule: any, value: string) => {
    if (!value) {
      return Promise.reject();
    }

    if (securitySetting === 'PIN') {
      // Check if the value is numeric for PIN
      const isNumeric = /^\d+$/.test(value);
      if (!isNumeric) {
        return Promise.reject(labelNumericOnly);
      }
      setTouched(false);
      return Promise.resolve();
    }

    // Validate password based on provided settings
    const minLength = parseInt(passwordValidationSetting.passwordMinLength, 10);
    const maxLength = parseInt(passwordValidationSetting.passwordMaxLength, 10);
    const rules = {
      length: value.length >= minLength && value.length <= maxLength,
      uppercase: passwordValidationSetting.hasUpper
        ? new RegExp(`[${passwordValidationSetting.upperCharacter}]`).test(
          value
        )
        : true,
      lowercase: passwordValidationSetting.hasLower
        ? new RegExp(`[${passwordValidationSetting.lowerCharacter}]`).test(
          value
        )
        : true,
      numeric: passwordValidationSetting.hasNumeric
        ? new RegExp(`[${passwordValidationSetting.numericCharacter}]`).test(
          value
        )
        : true,
      special: passwordValidationSetting.hasSpecial
        ? new RegExp(`[${passwordValidationSetting.specialCharacter}]`).test(
          value
        )
        : true,
    };

    if (
      !rules.length ||
      !rules.uppercase ||
      !rules.lowercase ||
      !rules.numeric ||
      !rules.special
    ) {
      return Promise.reject();
    }
    setTouched(false);
    return Promise.resolve();
  };

  return (
    <>
      {formType === 'change' && (
        <Form.Item
          layout="vertical"
          name="oldPassword"
          label={oldPasswordLabel}
          rules={[
            {
              required: true,
              message:
                securitySetting === 'PIN'
                  ? labelOldPinRequired
                  : labelOldPasswordRequired,
            },
          ]}
          required
          style={{}}
        >
          {securitySetting !== 'PIN' && (
            <Input.Password placeholder={labelInputPassword} />
          )}
          {securitySetting === 'PIN' && (
            <InputPin isPassword autoFocus length={6} />
          )}
        </Form.Item>
      )}
      <Form.Item>
        <Form.Item
          layout="vertical"
          name="newPassword"
          label={newPasswordLabel}
          rules={[
            {
              required: true,
              message:
                securitySetting === 'PIN'
                  ? labelNewPinRequired
                  : labelNewPasswordRequired,
            },
            {
              validator: validatePassword,
            },
          ]}
          style={{ marginBottom: 0 }}
        >
          {securitySetting !== 'PIN' && (
            <Input.Password
              placeholder={labelInputPassword}
              onChange={(event) => setPassword(event.target.value)}
              onFocus={() => setTouched(true)}
            />
          )}
          {securitySetting === 'PIN' && (
            <InputPin
              isPassword={true}
              autoFocus={formType !== 'change'}
              length={6}
            />
          )}
        </Form.Item>
        {/* {securitySetting === 'PIN' && (
          <Typography.Text type="secondary">
            {labelValidatePinInformation}
          </Typography.Text>
        )} */}
      </Form.Item>

      {securitySetting !== 'PIN' && (
        <PasswordValidator
          password={password}
          validationRules={passwordValidationSetting}
          touched={touched}
          type={securitySetting}
        />
      )}

      <Form.Item>
        <Form.Item
          name="confirmPassword"
          layout="vertical"
          label={confirmPasswordLabel}
          dependencies={['newPassword']}
          style={{ marginBottom: 0 }}
          rules={[
            {
              required: true,
              message:
                securitySetting === 'PIN'
                  ? labelConfirmPinRequired
                  : labelConfirmPasswordRequired,
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('newPassword') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error(
                    securitySetting === 'PIN'
                      ? labelPinNotMatch
                      : labelPasswordNotMatch
                  )
                );
              },
            }),
          ]}
        >
          {securitySetting !== 'PIN' && (
            <Input.Password placeholder={labelInputPassword} />
          )}
          {securitySetting === 'PIN' && (
            <InputPin isPassword={true} length={6} />
          )}
        </Form.Item>
        {securitySetting === 'PIN' && (
          <Typography.Text type="secondary">
            {labelBothPINMustMatch}
          </Typography.Text>
        )}
      </Form.Item>
    </>
  );
};

export default ChangePasswordForm;
