import { AxiosResponse, HttpStatusCode } from 'axios';
import { Flex, Form, Input } from 'antd';
import { forwardRef, useContext, useImperativeHandle } from 'react';

import { BaseResponse } from '@/lib/interfaces';
import ForceChangePinModal from '../force-change-pin/ForceChangePinModal';
import ForgotPinButton from '../forgot-pin';
import InputPin from '../../controls/InputPin';
import { LoginPinContext } from './context/LoginPinContext';
import { PinType } from './utils/enum';
import ShowToastNotificationResult from '../../show-toast-notification-result/ShowToastNotificationResult';
import WPForm from '../../wp-form/WPForm';
import axiosInstance from '@/lib/helpers/axiosInstance';
import { findInNestedArray } from '@/lib/helpers/findInNestedArray';
import useCookies from '@/lib/hooks/useCookies';
import { useTranslate } from '../../../hooks/useResourceKey';

interface LoginPinFormProps {
  onResponse: (values: { isUnlocked: boolean }) => void;
  setLoading: (loading: boolean) => void;
  loading: boolean;
  type: 'Page' | 'Area';
  targetPath?: string;
  menuCode?: string;
}

interface LoginPinResponse {
  pinToken: string;
}

type LoginPinErrorResponse = {
  MESSAGE: string;
  DATA: {
    message: string;
    isForceChangePin?: boolean;
  };
  ERRORS: string[];
  REQUEST_TIME: string;
  STATUS: boolean;
  HSTATUS: number;
};

const LoginPinForm = forwardRef<any, LoginPinFormProps>(
  ({ onResponse, setLoading, loading, type, targetPath, menuCode }, ref) => {
    const [FormInstance] = Form.useForm();
    const { createCookies, getMainCookie } = useCookies();
    const { pinType } = getMainCookie();
    const {
      isForceChangePinVisible,
      setIsForceChangePinVisible,
      oldPin,
      setOldPin,
    } = useContext(LoginPinContext);

    // Translation labels
    const labelError = useTranslate('Error', 'Error');
    const labelForceChangePinNoti = useTranslate(
      'ForceChangePinNotification',
      'You are required to change your PIN'
    );
    const labelUnexpectedError = useTranslate(
      'UnexpectedError',
      'Unexpected Error'
    );
    const labelPleaseEnterPIN = useTranslate(
      'PleaseEnterPIN',
      'Please Enter PIN'
    );
    const labelPin = useTranslate('PIN', 'PIN');
    const labelPinRequired = useTranslate('PINIsRequired', 'PIN is required');
    const labelPinMustBeNumeric = useTranslate(
      'PINMustBeNumeric',
      'PIN must be numeric'
    );

    // Expose submit and reset methods to parent component
    useImperativeHandle(ref, () => ({
      submit: () => {
        FormInstance.submit();
      },
      resetFields: () => {
        FormInstance.resetFields();
      },
    }));

    // Handle form submission
    const onSubmit = async (values) => {
      setLoading(true);
      try {
        let targetMenuCode = '';

        // Check if menuCode is passed through props
        if (!menuCode) {
          // Retrieve menuList from localStorage
          const menuList = JSON.parse(localStorage.getItem('menuList') || '[]');
          let actualPath = '';

          // Determine actualPath based on type
          if (type === 'Area') {
            actualPath = location.pathname;
          } else {
            actualPath = targetPath || '';
          }

          // Remove .add, .detail, or .history from the end of the path
          actualPath = actualPath.replace(/(\.add|\.detail|\.history)$/, '');

          // Find the target menu code from the menuList
          targetMenuCode = findInNestedArray(menuList, 'path', actualPath)?.key;
        } else {
          // Use menuCode passed through props
          targetMenuCode = menuCode;
        }

        // Make API request to login with pin
        const response: AxiosResponse<BaseResponse<LoginPinResponse>> =
          await axiosInstance.post('v1/hrm/security/login-pin', {
            pin: values.pin,
            menuCode: targetMenuCode,
          });

        // Create cookies with the pin token from the response
        createCookies('pinToken', { token: response.data.DATA.pinToken }, true);
        onResponse({ isUnlocked: true });
      } catch (e) {
        const error = e as LoginPinErrorResponse;

        // Handle different error scenarios
        if (
          error.HSTATUS === HttpStatusCode.Unauthorized &&
          error.DATA?.isForceChangePin &&
          pinType === PinType.DefineConfiguration
        ) {
          setIsForceChangePinVisible(true);
          setOldPin(values.pin);
          ShowToastNotificationResult({
            type: 'error',
            title: labelError,
            subtitle: labelForceChangePinNoti,
            autoClose: true,
            autoCloseSecond: 3,
          });
        } else if (error.HSTATUS === HttpStatusCode.Unauthorized) {
          ShowToastNotificationResult({
            type: 'error',
            title: labelError,
            subtitle: error.MESSAGE,
            autoClose: true,
            autoCloseSecond: 3,
          });
        } else {
          ShowToastNotificationResult({
            type: 'error',
            title: labelError,
            subtitle: labelUnexpectedError,
            autoClose: true,
            autoCloseSecond: 3,
          });
        }

        onResponse({ isUnlocked: false });
      }
      setLoading(false);
      FormInstance.resetFields();
    };

    // Validation rule for numeric PIN
    const numericRule = {
      validator: (_, value) => {
        if (!value || /^\d+$/.test(value)) {
          return Promise.resolve();
        }
        return Promise.reject(new Error(labelPinMustBeNumeric));
      },
    };

    return (
      <>
        {isForceChangePinVisible && <ForceChangePinModal />}
        <WPForm
          form={FormInstance}
          onFinish={onSubmit}
          layout="vertical"
          leaveGuard={false}
        >
          {pinType === PinType.SameAsPassword && (
            <Form.Item
              name="pin"
              label={labelPin}
              layout="vertical"
              rules={[{ required: true, message: labelPinRequired }]}
            >
              <Input.Password placeholder={labelPleaseEnterPIN} />
            </Form.Item>
          )}
          {pinType === PinType.DefineConfiguration && (
            <Form.Item
              name="pin"
              label={labelPin}
              layout="vertical"
              rules={[
                { required: true, message: labelPinRequired },
                numericRule,
              ]}
              style={{ display: 'flex', justifyContent: 'center' }}
            >
              <InputPin isPassword autoFocus length={6} />
            </Form.Item>
          )}

          <Flex justify={'flex-end'}>
            {pinType !== PinType.SameAsPassword && <ForgotPinButton />}
          </Flex>
        </WPForm>
      </>
    );
  }
);

export default LoginPinForm;
