import { Navigate, RouteObject, RouterProvider, createBrowserRouter } from 'react-router-dom';
import { lazy, useEffect, useState } from 'react';

import AuthProvider from './pages/auth/login/contexts/authProvider';
import ErrorBoundary from './pages/error/ErrorBoundary';
import LoadingComponent from 'lib/components/loading/LoadingComponent';
import Login from './pages/auth/login';
import NotFound from './pages/error/NotFound';
import OAuthCallback from './pages/auth/login/OAuthCallback';
import Protector from './middleware/Protector';
import SettingRoute from './routes/SettingRoute';
import WPAccess from './components/global/wp-access/WPAccess';
import { useStoreSelector } from './hooks/useStore';
import { useTranslation } from 'react-i18next';
import EmailApproval from './pages/restricted/email-approval';
import HumatrixIFrame from 'lib/components/templates/humatrix-i-frame/HumatrixIFrame';

const WPAppContainerDefault = lazy(() => import('components/global/wp-app-container/WPAppContainer'));
const WPStandardApp = lazy(() => import('components/global/wp-remote-components/WPStandardApp'));
const TestStandardComponent = lazy(() => import('@/pages/test/poc-config-provider'));

type RoutingProps = {
    cookies?: object | null;
    history?: unknown;
    isShowChatBot?: boolean;
};

const Routing = ({ cookies }: RoutingProps) => {
    const isUserLoggedIn = Boolean(cookies);
    const WPAppContainer = WPAppContainerDefault;
    const AllRoutes: RouteObject[] = [...SettingRoute];
    const virtualPath = process.env.VITE_APP_VIRTUAL_PATH || '/';

    const {
        i18n: { language },
    } = useTranslation();

    const { currentOU, currentRole } = useStoreSelector((state) => state.accountManagement);
    const isLoadingComponent = useStoreSelector((state: any) => state.persist.loadingComponent);

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const renderRoute = (route: RouteObject): RouteObject => {
        return {
            ...route,
        };
    };

    const router = createBrowserRouter(
        [
            {
                path: '/auth',
                element: <Login />,
            },
            {
                path: '/',
                element: isUserLoggedIn ? (
                    <AuthProvider>
                        {/* <LoginPinMiddleware /> */}
                        <Protector loadingCallback={(loading) => setIsLoading(loading)}>
                            <WPAppContainer>{isLoadingComponent || isLoading ? <LoadingComponent /> : <WPAccess />}</WPAppContainer>
                        </Protector>
                    </AuthProvider>
                ) : (
                    <Navigate to="/auth" />
                ),
                children: [
                    ...AllRoutes.map(renderRoute),
                    {
                        path: `standard/*`,
                        Component: WPStandardApp,
                    },
                    {
                        path: `standard/test/standard-component`,
                        Component: TestStandardComponent,
                    },
                    {
                        path: 'standardx/:path',
                        element: <HumatrixIFrame />,
                    },
                ],
                errorElement: <ErrorBoundary />,
            },
            {
                path: '/OauthCallback',
                element: <OAuthCallback />,
            },
            {
                path: '/restricted',
                element: <EmailApproval />,
            },
            {
                path: '*',
                element: <NotFound />,
            },
        ],
        {
            basename: virtualPath,
        }
    );

    useEffect(() => {
        setIsLoading(true);
        setTimeout(() => setIsLoading(false), 500);
    }, [language, currentOU, currentRole]);

    return <RouterProvider router={router} />;
};

export default Routing;
