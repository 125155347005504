import { Flex, Typography } from 'antd';
import React, { useRef, useState } from 'react';

import LoginPinForm from './LoginPinForm';
import { LoginPinProvider } from './context/LoginPinContext';
import SecurityModal from '../security-modal';
import WPZIcon from '../../wpz-icon/WPZIcon';
import changePasswordImage from '@/lib/assets/img/wp-change-pass-illustration.png';
import { useTranslate } from '../../../hooks/useResourceKey';

// Props for the LoginPinModal component
type LoginPinModalProps = {
  isVisible: boolean;
  onCancel: () => void;
  onResponse: (values: { isUnlocked: boolean }) => void;
  type: 'Page' | 'Area';
  targetPath?: string;
  menuCode?: string;
};

const LoginPinModal: React.FC<LoginPinModalProps> = ({
  isVisible,
  onCancel,
  onResponse,
  type,
  targetPath,
  menuCode,
}) => {
  // Translation labels
  const labelLoginPin = useTranslate('PleaseEnterPIN', 'Please Enter PIN');
  const labelSubmit = useTranslate('Submit', 'Submit');

  // State to manage loading status
  const [loading, setLoading] = useState(false);
  // Reference to the form component
  const formRef = useRef<any>(null);

  // Handle form submission
  const handleFormSubmit = () => {
    if (formRef.current) {
      formRef.current.submit();
    }
  };

  // Handle cancel action
  const handleCancel = () => {
    if (formRef.current) {
      formRef.current.resetFields();
    }
    onCancel();
  };

  return (
    <>
      <LoginPinProvider>
        <SecurityModal
          title={
            <Flex align="middle" gap={'small'}>
              <WPZIcon iconName="icon-lock" />
              <Typography>{labelLoginPin}</Typography>
            </Flex>
          }
          isVisible={isVisible}
          onSubmit={handleFormSubmit}
          submitButtonText={labelSubmit}
          isCloseable={true}
          isSubmitLoading={loading}
          onCancel={handleCancel}
          imageUrl={changePasswordImage}
        >
          <LoginPinForm
            ref={formRef}
            onResponse={onResponse}
            setLoading={setLoading}
            loading={loading}
            type={type}
            targetPath={targetPath}
            menuCode={menuCode}
          />
        </SecurityModal>
      </LoginPinProvider>
    </>
  );
};

export default LoginPinModal;
